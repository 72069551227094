import React, { useState, useEffect, useCallback } from 'react';

import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from '@react-google-maps/api';

import distributors from '../data/distributors-data.json';
import pcsmData from '../data/pcms-data.json';

import mapStyles from '../util/mapStyles';

//npmjs.com/package/@react-google-maps/api
const GMaps = ({ page = 'distributors' }) => {
  const content = page === 'distributors' ? distributors : pcsmData;
  const icon =
    page === 'distributors' ? `/logo-marker.svg` : `/logo-marker-home.svg`;
  console.log('content', content);
  const containerStyle = {
    width: '100%',
    height: '600px',
    margin: '0',
    padding: '0',
  };

  const center = { lat: 46.01714, lng: 8.90867 };
  const { isLoaded, loadError } = useLoadScript({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyBwF_TCcbmkctdkMfYgdAQKghv-kemq25A', //AIzaSyBwF_TCcbmkctdkMfYgdAQKghv-kemq25A'
  });
  //OLD 'AIzaSyBwF_TCcbmkctdkMfYgdAQKghv-kemq25A'; //PCSM 'AIzaSyBRnSSr03cFSDH_MNwxcJWcEuCucv4eIt0';
  const [map, setMap] = React.useState(null);

  const onLoad = useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    const bounds = new window.google.maps.LatLngBounds(center);

    content.features.map((model) => {
      const longitude = model.geometry.coordinates[0];
      const latitude = model.geometry.coordinates[1];
      const latLng = new window.google.maps.LatLng(latitude, longitude);
      bounds.extend(latLng);
      return latLng;
    });
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map) {
    setMap(null);
  }, []);
  // console.log('%cdistributors_data', 'color:green;background:white', distributors);
  const [selectedMarker, setSelectedMarker] = useState(null);
  // const [ setMap ] = useState(null);
  //let map = useRef(null);
  useEffect(() => {
    const listener = (e) => {
      if (e.key === 'Escape') {
        setSelectedMarker(null);
      }
    };
    window.addEventListener('keydown', listener);

    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, []);

  // console.log('loadError', loadError);
  // const renderMap = () => {
  return isLoaded && !loadError ? (
    <>
      <GoogleMap
        defaultZoom={12}
        defaultCenter={center}
        options={{ styles: mapStyles }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        mapContainerStyle={containerStyle}
      >
        {content.features.map((distributor) => {
          return (
            <Marker
              key={distributor.properties.ID}
              position={{
                lat: distributor.geometry.coordinates[1],
                lng: distributor.geometry.coordinates[0],
              }}
              onClick={() => {
                //pass map to arrow function (map) => {
                //console.log(map.getZoom());
                //map && smoothZoom(map, 12, map.getZoom());
                setSelectedMarker(distributor);
              }}
              icon={{
                url: icon,
                scaledSize: new window.google.maps.Size(55, 55),
              }}
            />
          );
        })}

        {selectedMarker && (
          <InfoWindow
            onCloseClick={() => {
              setSelectedMarker(null);
            }}
            position={{
              lat: selectedMarker.geometry.coordinates[1],
              lng: selectedMarker.geometry.coordinates[0],
            }}
          >
            <div>
              <p>
                <b>{selectedMarker.properties.NAME}</b>
              </p>
              <p>
                {selectedMarker.properties.TITLE}
                <br />
                {selectedMarker.properties.ADDRESS}
                <br />
                {selectedMarker.properties.ADDRESS1}
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  ) : (
    <p>loading...</p>
  );
  // };
  // if (loadError) {
  // 	return <div>Map cannot be loaded right now, sorry.</div>;
  // }

  // return isLoaded ? renderMap() : <p>loading...</p>;
};

export default GMaps;
