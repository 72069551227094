import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'; //Navigate
//import { injectIntl } from 'react-intl';
import Home from './pages/Home';
import Production from './pages/Production';
import Wines from './pages/Wines';
import Distributors from './pages/Distributors';
import BnB from './pages/BnB';
import ContactUs from './pages/ContactUs';

const AppRoutes = (props) => {
	return (
		<>
			<Routes>
				{/* <Route  path="/" render={() => (<Navigate to="/it/" replace/>)}/> */}
				<Route  path="/:language/" element={<Home {...props} />} />
				<Route  path="/:language/production" element={<Production {...props} />} />
				<Route  path="/:language/wines" element={<Wines {...props} />} />
				<Route  path="/:language/distributors" element={<Distributors {...props} />} />
				<Route  path="/:language/bnb" element={<BnB {...props} />} />
				<Route  path="/:language/contact-us" element={<ContactUs {...props} />} />
				<Route path="/" element={<Navigate replace to="/it/" />} />
			</Routes>
		</>
	);
};

export default AppRoutes;
