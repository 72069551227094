import React from 'react';
const Gallery = ({urls}) => {
    /* function onClickCool(e) {
      const clone = e.target.parentElement.cloneNode(true);
      const ZoomedIn = document.querySelector(".ZoomedIn");
      ZoomedIn.innerHTML = "";
      ZoomedIn.appendChild(clone);
    } */
    const onClick = (e) => {
      let GalleryEl = document.querySelector(".gallery");
      const clone = e.target.parentElement.cloneNode(true);
      const elImage = e.target;
      const firstRect = elImage.getBoundingClientRect();
      const elDetail = document.querySelector(".ZoomedIn");
      elDetail.innerHTML = "<div class='close'> X </div>";
      elDetail.appendChild(clone);
      requestAnimationFrame(() => {
        let elCloneImage = elDetail.querySelector("img");
        
        const lastRect = elCloneImage.getBoundingClientRect();
  
        // INVERT
        const deltaX = firstRect.left - lastRect.left;
        const deltaY = firstRect.top - lastRect.top;
        // const deltaW = firstRect.width / lastRect.width;
        // const deltaH = firstRect.height / lastRect.height;
        const deltaW = (lastRect.width>0)? firstRect.width / lastRect.width : 0.5;
        const deltaH = (lastRect.height>0)? firstRect.height / lastRect.height : 0.5;
        
    console.log(firstRect.height/lastRect.width);
        elCloneImage.animate(
          [
            {
              transform: `translateX(${deltaX}px) translateY(${deltaY}px) scale(${deltaW}, ${deltaH})`
            },
            { transform: "none" }
          ],
          { duration: 600, easing: "cubic-bezier(.2, 0, .3, 1)" }
        );
        GalleryEl.dataset.state = "detail";
      });
    }
    // const urls = Array(20).fill(
    //   "https://source.unsplash.com/collection/2022043/700x900"
    // );
    // console.log(urls);
    return (
      // eslint-disable-next-line react/react-in-jsx-scope
      <>
        <ZoomedIn />
        <div className="Grid">
          {urls.map((url, i) => (
            <Figure key={i} onClick={onClick} url={url} i={i} />
          ))}
        </div>
      </>
    );
  }
  
  const Figure = ({ url, onClick, i }) => {
    return (
      <figure onClick={e => onClick(e)} data-key={i} className="Figure">
        {/* <img src={`${url}=${i}`} alt="imagy" />*}{/*`${url}=${i}`*/}
        <img src={`${url}`} alt="Orso e Ape {i}" />{/*`${url}=${i}`*/}
      </figure>
    );
  }
  
  const ZoomedIn = ({ url, i }) => {
    function onClick() {
      let GalleryEl = document.querySelector(".gallery");
  
      GalleryEl.dataset.state = "gallery";
    }
    return (
      <div onClick={onClick} className="ZoomedIn">
        {/* <img src={`${url}=${i}`} alt="imagy" /> */}
      </div>
    );
  }

  export default Gallery;