import en from './en';
import it from './it';

/* export default {
	en,
	it
}; */
const allLangJson = {
	en,
	it
};

export default allLangJson;
