import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Gallery from '../components/Gallery';
import Page from '../templates/Page';

import { OrsoeApe } from '../components/SvgIcons';

import VideoThumb from '../images/bnb/video-2-screen.jpg';
import BnbImage1 from '../images/bnb/bnb2.jpg';
import BnbImage4 from '../images/bnb/bnb4.jpg';
import BnbImage5 from '../images/bnb/bnb5.jpg';

const BnB = (props) => {
	const [ isVideoLoaded, setIsVideoLoaded ] = useState(false);

	const room1 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5110.jpg';
	const room2 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5309.jpg';
	const room3 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5274.jpg';
	const room4 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5238.jpg';
	const room5 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5211.jpg';
	const room6 = 'https://www.poderecollesanmassimo.com/assets/images/bnb/CVD5126.jpg';

	const arrImagesRooms = [ room1, room2, room3, room4, room5, room6 ];

	const desktopVideoMp4 = 'https://www.poderecollesanmassimo.com/assets/video/video-1-1080p.mp4';
	const tabletVideoMp4 = 'https://www.poderecollesanmassimo.com/assets/video/video-1-720p.mp4';
	//const mobileVideoMp4 = 'https://outrightgroup.it/poderecollesanmassimo/new/assets/video-1-480p.mp4';

	const desktopVideoWebm = 'https://www.poderecollesanmassimo.com/assets/video/video-1-1080p.webm';
	const tabletVideoWebm = 'https://www.poderecollesanmassimo.com/assets/video/video-1-720p.webm';
	//const mobileVideoWebm = 'https://outrightgroup.it/poderecollesanmassimo/new/assets/video-1-480p.webm';

	const getVideoSrc = (width, type) => {
		if (type === 'mp4') {
			if (width >= 1080) return desktopVideoMp4; //1080p version for desktop
			//if (width >= 720) return tabletVideoMp4; //720p version for tablets
			//return mobileVideoMp4; //480p version for mobile devices
			return tabletVideoMp4;
		}
		if (type === 'webm') {
			if (width >= 1080) return desktopVideoWebm; //1080p version for desktop
			//if (width >= 720) return tabletVideoWebm; //720p version for tablets
			//return mobileVideoWebm; //480p version for mobile devices
			return tabletVideoWebm;
		}
	};
	const srcWebm = getVideoSrc(window.innerWidth, 'webm');
	const srcMp4 = getVideoSrc(window.innerWidth, 'mp4');

	const onLoadedData = () => {
		setIsVideoLoaded(true);
	};

	//https://assets.yesstud.io/studioblvd/video/studioblvd_420.mp4
	return (
		<Page {...props}>
			<div className="bnb-features">
				<div className="video-header">
					<img
						src={VideoThumb}
						className="video-thumb"
						alt="thumb"
						style={{ opacity: isVideoLoaded ? 0 : 1, display: isVideoLoaded ? 'none' : 'block' }}
					/>
					<video
						autoPlay
						playsInline
						muted
						poster={VideoThumb}
						loop="loop"
						onLoadedData={onLoadedData}
						style={{ opacity: isVideoLoaded ? 1 : 0 }}
					>
						<source src={srcWebm} type="video/webm;codecs=&quot;vp8, vorbis&quot;" />
						<source src={srcMp4} type="video/mp4;codecs=&quot;avc1.42E01E, mp4a.40.2&quot;" />
					</video>

					<div className="viewport-header">
						<h1>
							<span>
								<FormattedMessage id="bnb.nonsolo" />
							</span>
							<FormattedMessage id="bnb.vino" />
						</h1>
					</div>
					{/* 
						codec specified for faster loading
						<video
							autoPlay
							playsInline
							muted
							src={src}
							onLoadedData={onLoadedData}
							style={{ opacity: isVideoLoaded ? 1 : 0 }}
						/>
						//OLD
						<video loop="loop" muted="muted" autoPlay="autoplay">
							<source src="https://outrightgroup.it/poderecollesanmassimo/new/video-2.mp4" />
						</video> */}
				</div>
				<div className="bnb-content">
					<div className="container-fluid">
						<div className="text">
							<h1>
								<FormattedMessage id="bnb.title-1" />
							</h1>
							<p>
								<FormattedMessage id="bnb.content-1" />
							</p>
							<br />
							<p>
								<FormattedMessage id="bnb.content-2" />
							</p>
						</div>
						<div className="image">
							<img src={BnbImage1} alt="Orso e Ape" />
						</div>
					</div>
					<div className="container-fluid reverse blk-bkg">
						<div className="text">
							<h2>
								<FormattedMessage id="bnb.title-3" />
							</h2>
							<p>
								<FormattedMessage id="bnb.content-6" />
							</p>
							<br />
							<p>
								<FormattedMessage id="bnb.content-7" />
							</p>
						</div>
						<div className="image">
							<img src={BnbImage5} alt="Orso e Ape" />
						</div>
					</div>
					<div className="container-fluid">
						<div className="text">
							<h2>
								<FormattedMessage id="bnb.title-2" />
							</h2>
							<p>
								<FormattedMessage id="bnb.content-3" />
							</p>
							<br />
							<p>
								<FormattedMessage id="bnb.content-4" />
							</p>
							<br />
							<p>
								<strong>
									<FormattedMessage id="bnb.content-5" />
								</strong>
							</p>
							<br />
							<p>
								<FormattedMessage id="bnb.content-8" />
								<a
									href="http://orsoeape.it"
									title="Orso e Ape Bed and Breakfast"
									target="_blank"
									rel="noopener noreferrer"
								>
									<FormattedMessage id="bnb.website" />
								</a>
							</p>
							<a
								href="http://orsoeape.it"
								title="Orso e Ape Bed and Breakfast"
								target="_blank"
								rel="noopener noreferrer"
							>
								<span className="big-icon">
									<OrsoeApe />
								</span>
							</a>
						</div>
						<div className="image">
							<img src={BnbImage4} alt="Orso e Ape" />
						</div>
					</div>
					<div data-state="gallery" className="gallery">
						<Gallery urls={arrImagesRooms} />
					</div>
				</div>
			</div>
		</Page>
	);
};

export default BnB;
