import React, { useState, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
//https://blog.bitsrc.io/how-to-build-a-contact-form-with-react-js-and-php-d5977c17fec0
//https://blog.alexdevero.com/contact-form-react-ajax-php-recaptcha/ <-- Recaptcha
//https://blog.jakoblind.no/react-forms-hooks/
import { useIntersection } from 'react-use';
//import useOnScreen from '../hooks/useOnScreen';
//import gsap from 'gsap/all';
import { fadeIn, fadeOut } from '../util/Animations';

import Page from '../templates/Page';
import GMaps from '../components/GMaps';
import ContactImg from '../images/contactus/contactus1.jpg';

//message form custom hook
const useMessageForm = (callback) => {
	const [ inputs, setInputs ] = useState({});

	const handleSubmit = (event) => {
		if (event) {
			event.preventDefault();
		}

		callback();
		setInputs({ firstName: '', lastName: '', email: '', message: '' });
		//console.log(inputs);
	};
	const handleInputChange = (event) => {
		event.persist();
		setInputs((inputs) => ({ ...inputs, [event.target.name]: event.target.value }));
	};
	return {
		handleSubmit,
		handleInputChange,
		inputs
	};
};

const ContactUs = (props) => {
	const [ result, setResult ] = useState(false);

	let image1 = useRef(null);
	const tresholdPercentage = 0.4;
	// All the ref to be observed
	const intersectionImage1 = useIntersection(image1, {
		root: null,
		rootMargin: '0px',
		threshold: tresholdPercentage
	});
	//INTERSECTION OBSERVER TURNARY CHECK
	// checking to see when the vieport is visible to the user
	intersectionImage1 && intersectionImage1.intersectionRatio < tresholdPercentage
		? fadeOut('.image1-fadeIn') //not reached, so animate out
		: fadeIn('.image1-fadeIn'); //reached, so animate in

	async function submitMessageForm() {
		//e.preventDefault();
		const messageData = {
			firstName: inputs.firstName,
			lastName: inputs.lastName,
			email: inputs.email,
			message: inputs.message
		};
		try {
			const response = await fetch(`https://www.poderecollesanmassimo.com/rest_mail_send.php`, {
				method: 'POST',
				body: JSON.stringify(messageData)
			});
			const json = await response.json();
			console.log(json.sent);
			setResult(json.sent);
		} catch (e) {
			setResult(e.message);
		}
		// alert(`User message sent!
		// 	   Name: ${inputs.firstName} ${inputs.lastName}
		// 	   Email: ${inputs.email} Message ${inputs.message}`);
		//reset form fields
	}
	const { inputs, handleInputChange, handleSubmit } = useMessageForm(submitMessageForm);
	return (
		<Page {...props}>
			<GMaps vhHeight="70" vwWidth="100" page="pcsm" />
			<section className="contact-us-features">
				<div className="container-fluid">
					<div className="text">
						<h1>
							<FormattedMessage id="contactus.title" />
						</h1>
						{result && (
							<div>
								<h2>
									<FormattedMessage id="contactus.success-message-1" />
								</h2>
								<h3>
									<FormattedMessage id="contactus.success-message-2" />
								</h3>
							</div>
						)}
						<div>
							<form onSubmit={handleSubmit}>
								<label>
									<FormattedMessage id="contactus.firstname-label" />
								</label>
								<FormattedMessage id="contactus.firstname-placeholder">
									{(placeholder) => (
										<input
											type="text"
											name="firstname"
											placeholder={placeholder}
											value={inputs.firstName}
											onChange={handleInputChange}
										/>
									)}
								</FormattedMessage>

								<label>
									<FormattedMessage id="contactus.lastname-label" />
								</label>
								<FormattedMessage id="contactus.lastname-placeholder">
									{(placeholder) => (
										<input
											type="text"
											name="lastname"
											placeholder={placeholder}
											value={inputs.lastName}
											onChange={handleInputChange}
										/>
									)}
								</FormattedMessage>
								<label>
									<FormattedMessage id="contactus.email-label" />
								</label>
								<FormattedMessage id="contactus.email-placeholder">
									{(placeholder) => (
										<input
											type="text"
											name="email"
											placeholder={placeholder}
											value={inputs.email || ''}
											onChange={handleInputChange}
										/>
									)}
								</FormattedMessage>

								<label>
									<FormattedMessage id="contactus.message-label" />
								</label>
								<FormattedMessage id="contactus.message-placeholder">
									{(placeholder) => (
										<input
											type="text"
											name="message"
											placeholder={placeholder}
											value={inputs.message}
											onChange={handleInputChange}
										/>
									)}
								</FormattedMessage>
								<small>
									<FormattedMessage id="contactus.privacy-agreement" />
								</small>
								<button type="submit">
									<FormattedMessage id="contactus.button" />
								</button>
							</form>
						</div>
					</div>
					<div className="image image1-fadeIn" ref={image1}>
						<img src={ContactImg} alt="Podere Colle San Massimo" />
					</div>
				</div>
			</section>
		</Page>
	);
};

export default ContactUs;
