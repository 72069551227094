import React from 'react';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faFacebook, faTwitter, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Facebook, Whatsapp, Youtube, Instagram } from './SvgIcons';
import WhatsAppWidget from 'react-whatsapp-chat-widget';
import 'react-whatsapp-chat-widget/index.css';
import { useParams } from 'react-router-dom';

//SVG
import { ReactComponent as LogoFooter } from '../assets/logo-footer.svg';
const Footer = () => {
  let { language } = useParams();
  const widgetMessage =
    language === 'it' ? (
      <>
        Ciao! 👋 <br /> Come possiamo aiutarti?
      </>
    ) : (
      <>
        Hi there 👋 <br /> How can we help you?
      </>
    );
  const messageBoxTxt =
    language === 'it'
      ? 'Ciao, siamo qui per aiutarti!'
      : "Hello, we're here to help!";
  const startChatBtnTxt = language === 'it' ? 'Inizia Chat' : 'Start Chat';
  return (
    <>
      <footer>
        <div>
          <LogoFooter className="logo" />
        </div>

        <div className="footer-wrapper">
          <div className="footer-side">
            <h3>Podere Colle San Massimo Soc. Agricola</h3>
            <p className="company-data">
              Via Colle San Massimo, 16 <br />
              64021 Giulianova (TE) - Italy <br />
              P.IVA / VAT: 01814920672 - SDI QULXG4S
            </p>
            <br />
            <a
              href="mailto:info@poderecollesanmassimo.com "
              title="Send an Email to Podere Colle San Massimo"
            >
              info@poderecollesanmassimo.com
            </a>
          </div>
          <div className="footer-side">
            <p className="company-data">
              <p>e-Mail:</p>
              <a
                href="mailto:info@poderecollesanmassimo.com "
                title="Send an Email to Podere Colle San Massimo"
              >
                info@poderecollesanmassimo.com
              </a>
              <br />
              <br />
              <p>Tel/Phone:</p>

              <a
                href="tel:00393355650784 "
                title="Call Podere Colle San Massimo"
              >
                {/* <span className="icon">
									<PhoneIcon />
								</span> */}
                +39 335 565 0784
              </a>
              <br />
              <a
                href="tel:00393398512963 "
                title="Call Podere Colle San Massimo"
              >
                {/* <span className="icon">
									<PhoneIcon />
								</span> */}
                +39 339 851 2963
              </a>
            </p>
            <div className="social-icons">
              <a
                href="https://www.facebook.com/poderecollesanmassimo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook />
              </a>

              <a
                href="https://www.youtube.com/channel/UCByeU18wTrIwMwn1eaNjNCA"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Youtube />
              </a>
              <a
                href="https://www.instagram.com/podere_colle_san_massimo/?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=393355650784"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Whatsapp />
              </a>
            </div>
          </div>
        </div>

        <div className="bottom">2025 Podere Colle San Massimo</div>
      </footer>
      <WhatsAppWidget
        phoneNo="393355650784"
        position="right"
        widgetWidth="300px"
        widgetWidthMobile="260px"
        autoOpen={true}
        autoOpenTimer={5000}
        messageBox={true}
        messageBoxTxt={messageBoxTxt}
        iconSize="40"
        iconColor="white"
        iconBgColor="#3fc350"
        headerIcon="https://poderecollesanmassimo.com/logo.svg"
        headerIconColor="pink"
        headerTxtColor="black"
        headerBgColor="#ffffff"
        headerTitle="PodereColleSanMassimo"
        headerCaption="Online"
        bodyBgColor="#bbb"
        chatPersonName="Support - Supporto"
        chatMessage={widgetMessage}
        footerBgColor="#ffffff"
        btnBgColor="#DEC8C1"
        btnTxtColor="black"
        btnTxt={startChatBtnTxt}
      />
    </>
  );
};

export default Footer;
