import React from 'react';
import { FormattedMessage } from 'react-intl';
//import * as coolData from '../data/distributors-data.json';
import Page from '../templates/Page';
// import ShopOnline from '../components/ShopOnline';
import GMaps from '../components/GMaps';

const Distributors = (props) => {
  //const distributorsArray = [ 'italy', 'france', 'canada', 'switzerland' ];
  /* SETTING OF GMAPS IN distributors-data.json */
  const distributorsArray = ['france', 'switzerland', 'canada', 'singapore'];

  return (
    <Page {...props}>
      <div className="map-container">
        <GMaps />
      </div>
      <section className="distributors-features">
        <div className="container-fluid">
          <div className="title">
            <h1 className="text-center">
              <FormattedMessage id={`distributors.title`} />
            </h1>
          </div>
        </div>

        <div className="container-grid">
          <div className="grid-row">
            {distributorsArray.map((zone) => (
              <div key={zone} className="grid-item">
                <h3>
                  <FormattedMessage id={`distributors.${zone}.qualify`} />
                </h3>
                <p>
                  <b>
                    <FormattedMessage id={`distributors.${zone}.title`} />
                  </b>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.address-title`} />
                  <FormattedMessage id={`distributors.${zone}.address`} />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.address-1`} />

                  <br />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.email-title`} />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.email1`}>
                    {(link) => (
                      <a href={`mailto:${link}`} title={`Contact ${link}`}>
                        <FormattedMessage id={`distributors.${zone}.email1`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.email2`}>
                    {(link) => (
                      <a href={`mailto:${link}`} title={`Contact ${link}`}>
                        <FormattedMessage id={`distributors.${zone}.email2`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.email3`}>
                    {(link) => (
                      <a href={`mailto:${link}`} title={`Contact ${link}`}>
                        <FormattedMessage id={`distributors.${zone}.email3`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.email4`}>
                    {(link) => (
                      <a href={`mailto:${link}`} title={`Contact ${link}`}>
                        <FormattedMessage id={`distributors.${zone}.email4`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.website-title`} />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.website`}>
                    {(link) => (
                      <a
                        href={`${link}`}
                        title="Website"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FormattedMessage id={`distributors.${zone}.website`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.fax-title`} />
                  <FormattedMessage id={`distributors.${zone}.fax-formatted`}>
                    {(link) => (
                      <a href={`fax:${link}`} title="Fax">
                        <FormattedMessage id={`distributors.${zone}.fax`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.phone-1-title`} />
                  <FormattedMessage
                    id={`distributors.${zone}.phone-1-formatted`}
                  >
                    {(link) => (
                      <a href={`phone:${link}`} title="Phone">
                        <FormattedMessage id={`distributors.${zone}.phone-1`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.phone-2-title`} />
                  <FormattedMessage
                    id={`distributors.${zone}.phone-2-formatted`}
                  >
                    {(link) => (
                      <a href={`phone:${link}`} title="Phone">
                        <FormattedMessage id={`distributors.${zone}.phone-2`} />
                      </a>
                    )}
                  </FormattedMessage>
                  <br />
                  <FormattedMessage id={`distributors.${zone}.zones-title`} />
                  <br />
                  <FormattedMessage id={`distributors.${zone}.zones`} />
                </p>
              </div>
            ))}
          </div>
        </div>
        <div className="container-grid">
          <div>{/* <ShopOnline /> */}</div>
        </div>
      </section>
    </Page>
  );
};

export default Distributors;
