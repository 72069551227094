import layout from './layout.json';
import home from './home.json';
import production from './production.json';
import wines from './wines.json';
import distributors from './distributors.json';
import bnb from './bnb.json';
import contactus from './contactus.json';
import fields from './fields.json';

// import { defineMessages } from 'react-intl';
// const messages = defineMessages({
// 	numplayers: '{count, plural, =0 {nessun giocatore} one {# giocatore} other {# giocatori}}'
// });

/* export default {
	layout,
	home,
	production,
	wines,
	distributors,
	bnb,
	contactus,
	fields
	//messages
}; */
const allLangJson = {
	layout,
	home,
	production,
	wines,
	distributors,
	bnb,
	contactus,
	fields
	//messages
};

export default allLangJson;
