import React from 'react';
//import PropTypes from 'prop-types';
// import { withRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { useParams } from 'react-router-dom';

import Layout from './Layout';
import allMessages from '../i18n';
import { flatten } from '../i18n/i18n-utils';

function getMessages(language) {
	const locale = allMessages[language] ? language : 'en';
	const nestedMessages = allMessages[locale];
	const messages = flatten(nestedMessages);
	return { messages, locale };
}

/**
 * `Page` template used to create all application pages, for logged-in users
 */
const Page = (props) => {
	const { children } = props;
	const params = useParams();
	// console.log('params', params);
	const { messages, locale } = getMessages(params.language);
	return (
		<IntlProvider locale={locale} messages={messages}>
			<Layout {...props}>{children}</Layout>
		</IntlProvider>
	);
	/*
	const { children, match } = props;
	const { language } = match.params;
	const { messages, locale } = getMessages(language);
	//console.log(messages);
	return (
		<IntlProvider locale={locale} messages={messages}>
			<Layout {...props}>{children}</Layout>
		</IntlProvider>
	);
	*/
};

// export default withRouter(Page);
export default Page;
