import React, { useEffect, useRef, useCallback } from 'react';
import { useMatch, NavLink } from 'react-router-dom';

import { gsap } from 'gsap';

import { FormattedMessage } from 'react-intl';
// import { withRouter } from 'react-router-dom';
//COMPONENTS
import Menu from './Menu';
import MobileMenu from './MobileMenu';

//IMAGES
//import Logo from '../images/logo_transparent.png';
import { ReactComponent as LogoHeader } from '../assets/logo.svg';

const Header = (props) => {
	//console.log(props.location.pathname);
	let section = useMatch("/:language/:to");
	//if no route (home section) HIDE LOGO
	const logoHide = section?1:0;
	

	//console.log(props.match);
	const animationRef = useRef();
	const timelineRef = useRef();
	const elementRef = useRef();
	const logoRef = useRef();

	const progressValue = (val, max, min) => {
		return (val - min) / (max - min);
	};

	const animate = useCallback(
		() => {
			const scrollPosY = props.scroll.y;
			const ease = 0.25;
			const scrollMax = 50;
			const scrollMin = 1;
			let scrollCurrent = 0;
			// Reset if less than scrollMin
			if (scrollPosY < scrollMin) {
				timelineRef.current.seek(0);
			}

			// Calc scroll amount with ease
			scrollCurrent += (scrollPosY - scrollCurrent) * ease;

			// Error correction
			if (scrollCurrent) {
				// Define scroll amount based on max/min
				const progress = progressValue(scrollCurrent, scrollMax, scrollMin);
				// Set timeine process amount
				timelineRef.current.progress(progress);
			}
		},
		[ props.scroll.y ]
	);

	useEffect(
		() => {
			// Create new timeline
			const timeline = new gsap.timeline({ paused: true });

			// Define timeine parameters
			timeline.fromTo(
				elementRef.current,
				1,
				{ backgroundColor: '#dfdfdf', opacity: 0.8, height: 60 },
				{ backgroundColor: '#1E1B11', opacity: 1, height: 90 }
			);
			

			timeline.fromTo(
				'.menu-item',
				1,
				{ color: '#1E1B11', fontWeight: 400 },
				{ color: '#dfdfdf', fontWeight: 300 }
			); //#ddc6bf
			timeline.fromTo(
				'.bar>div',
				1,
				{ backgroundColor: '#1E1B11' },
				{ backgroundColor: '#ddc6bf' }
			);
			timeline.fromTo(
				'.fixed-nav',
				1,
				{ marginTop: 0 },
				{ marginTop: 20 }
			);
			
			timeline.fromTo('.language', 1, { color: '#1E1B11' }, { color: '#ddc6bf' });
			timeline.to('.header-logo', 1, { display: 'block' });
			timeline.fromTo(
				logoRef.current,
				1,
				{ width: 50, height: 50, marginTop: 5, opacity: logoHide },
				{ width: 80, height: 80, marginTop: -10, opacity: 1 }
			);

			// Store timeline as ref
			timelineRef.current = timeline;

			// Requestion animation and store ref
			animationRef.current = requestAnimationFrame(animate);

			return () => {
				// Cancel animation on unload
				cancelAnimationFrame(animationRef.current);
			};
		},
		[ animate,logoHide ]
	);
	
	return (
		<>
			<header >
				<div ref={elementRef} className="header">
					<div className="menu">
						<div className="header-logo">
							
								<NavLink to="/" exact>
									
									<FormattedMessage id={'layout.header'}>
										{/*(title) => <img className="logo" src={Logo} ref={logoRef} title={title} alt={title} />*/}
										{
												(title) => <LogoHeader className="logo" ref={logoRef} title={title} alt={title} />
										}
									</FormattedMessage>
									
								</NavLink>
							
						</div>
						<Menu section={section&&section.params.to} />
					</div>
				</div>
				<MobileMenu />
			</header>
		</>
	);
};

// export default withRouter(Header);
export default Header;
